(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/cookie/assets/javascripts/cookie.js') >= 0) return;  svs.modules.push('/components/utils/cookie/assets/javascripts/cookie.js');

'use strict';

svs.utils = svs.utils || {};
svs.utils.cookie = svs.utils.cookie || {};

var logger = svs.core.log.getLogger('core:cookie');

svs.utils.cookie.readAll = function() {
  var cookies = {};

  document.cookie
    .split(';')
    .map(function(m) {
      return m.replace(/^\s+/, '').replace(/\s+$/, '');
    })
    .forEach(function(c) {
      var arr = c.split('=');
      var key = arr[0];
      var value = null;

      if (arr.length > 1) {
        value = arr.slice(1).join('');
      }

      cookies[key] = value;
    });

  return cookies;
};

svs.utils.cookie.read = function(name) {
  var cookie = null;
  var list = this.readAll();

  Object.keys(list).forEach(function(key) {
    if (key === name) {
      cookie = list[key];
    }
  });

  return cookie;
};

svs.utils.cookie.set = function(name, value, options) {
  var cookieString;
  var secondsInADay = 86400;

  if (!name) {
    logger.error('missing name when setting cookie!');
    return;
  }

  if (!value && value !== false) {
    logger.error('missing value when setting cookie!');
    return;
  }

  cookieString = name + '=' + value + ';path=/;';

  if (options && options.domain) {
    cookieString += 'domain=' + options.domain + ';';
  }

  if (options && options.daysToExist) {
    var seconds = options.daysToExist * secondsInADay;
    cookieString += 'max-age=' + seconds + ';';
  }

  if (options && options.secure) {
    cookieString += 'secure;';
  }

  if (options && options.httpOnly) {
    cookieString += 'HttpOnly;';
  }

  document.cookie = cookieString;
};

svs.utils.cookie.destroy = function(name, domain) {
  var cookiestring;
  if (svs.utils.cookie.read(name)) {
    cookiestring = name + '=; Path=/; Max-Age=0;';
    if (domain) {
      cookiestring += 'domain="' + domain + '";';
    }
    document.cookie = cookiestring;
  }
};


 })(window);